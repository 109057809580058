import React from 'react';
import { Typography } from '@mui/material';

const Subscriptions = () => (
    <div>
        <Typography variant="h3">Subscriptions</Typography>
    </div>
);

export default Subscriptions;
