import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './components/Dashboard';
import Announcements from './pages/Announcements';
import Notifications from './pages/Notifications';
import Payments from './pages/Payments';
import Residents from './pages/Residents';
import Security from './pages/Security';
import Subscriptions from './pages/Subscriptions';
import Surveys from './pages/Surveys';
import Visitors from './pages/Visitors';
import UserManagement from './pages/UserManagement';
import Addresses from './pages/Addresses.js';
import Home from './pages/Home';
import Login from './components/Login';
import LoadingSpinner from './components/LoadingSpinner';
import { validateToken } from './utils/auth';

const PrivateRoute = ({ element }) => {
    const [isValid, setIsValid] = React.useState(null);

    React.useEffect(() => {
        const checkToken = async () => {
            const valid = await validateToken();
            setIsValid(valid);
        };
        checkToken();
    }, []);

    if (isValid === null) {
        return <LoadingSpinner />;
    }

    return isValid ? element : <Navigate to="/login" />;
};

const App = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<PrivateRoute element={<Dashboard />} />}>
                        <Route index element={<Home />} />
                        <Route path="announcements" element={<Announcements />} />
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="payments" element={<Payments />} />
                        <Route path="residents" element={<Residents />} />
                        <Route path="security" element={<Security />} />
                        <Route path="subscriptions" element={<Subscriptions />} />
                        <Route path="surveys" element={<Surveys />} />
                        <Route path="visitors" element={<Visitors />} />
                        <Route path="user-management" element={<UserManagement />} />
                        <Route path="addresses" element={<Addresses />} />
                    </Route>
                </Routes>
            </Router>
            <LoadingSpinner />
            <ToastContainer />
        </>
    );
};

export default App;
