import React, { useState, useEffect } from 'react';
import {
    Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Typography, MenuItem, Select, InputLabel, FormControl, TablePagination
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon } from '@mui/icons-material';
import userService from '../services/userService';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0); // Página actual
    const [rowsPerPage, setRowsPerPage] = useState(5); // Filas por página

    // Función para cargar los usuarios desde el backend al inicio
    const fetchUsers = async () => {
        try {
            const usersData = await userService.getAllUsers();
            setUsers(usersData); // Asigna la lista de usuarios al estado
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers(); // Cargar usuarios al montar el componente
    }, []);

    const handleOpen = (user = {}) => {
        setIsEditing(!!user.id);
        setCurrentUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentUser({});
    };

    const handleChange = (e) => {
        setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        try {
            if (isEditing) {
                await userService.updateUser(currentUser.id, currentUser);
            } else {
                await userService.createUser(currentUser);
            }
            fetchUsers(); // Actualizar la lista de usuarios después de guardar
            handleClose();
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await userService.deleteUser(id);
            fetchUsers(); // Actualizar la lista de usuarios después de eliminar
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setPage(0); // Reiniciar la página a 0 al filtrar
    };

    const filterUsers = (users) => {
        return users.filter((user) =>
            user.id.toString().includes(filter) ||
            user.username.toLowerCase().includes(filter.toLowerCase()) ||
            user.email.toLowerCase().includes(filter.toLowerCase()) ||
            user.role.toLowerCase().includes(filter.toLowerCase()) ||
            user.status.toLowerCase().includes(filter.toLowerCase())
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredUsers = filterUsers(users);
    const paginatedUsers = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                <Typography variant="h4">Administracion de usuarios</Typography>
                <Button variant="contained" color="primary" onClick={() => handleOpen()}>
                    <AddIcon /> Nuevo usuario
                </Button>
            </Box>
            <Box display="flex" alignItems="right" mt={2}>
                <TextField
                    label="Buscar"
                    value={filter}
                    onChange={handleFilterChange}
                    margin="normal"
                    fullWidth
                    InputProps={{
                        endAdornment: <SearchIcon />,
                    }}
                />
            </Box>
            <TableContainer component={Paper} sx={{ mt: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nombre de usuario</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Rol</TableCell>
                            <TableCell>Estatus</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedUsers.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.id}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.role === 'ADMIN' ? 'Administrador' : user.role === 'USER' ? 'Residente' : 'No establecido'}</TableCell>
                                <TableCell>{user.status === 'Active' ? 'Activo' : user.status === 'Disabled' ? 'Inactivo' : 'No establecido'}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleOpen(user)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDelete(user.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={filteredUsers.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
            />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEditing ? 'Editar Usuario' : 'Añadir Usuario'}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="username"
                        label="Nombre de usuario"
                        type="text"
                        fullWidth
                        value={currentUser.username || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={currentUser.email || ''}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Rol</InputLabel>
                        <Select
                            name="role"
                            value={currentUser.role || ''}
                            onChange={handleChange}
                        >
                            <MenuItem value="USER">Usuario</MenuItem>
                            <MenuItem value="ADMIN">Administrador</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Estatus</InputLabel>
                        <Select
                            name="status"
                            value={currentUser.status || ''}
                            onChange={handleChange}
                        >
                            <MenuItem value="Active">Activo</MenuItem>
                            <MenuItem value="Disabled">Inactivo</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {isEditing ? 'Actualizar' : 'Aceptar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default UserManagement;
