import axios from 'axios';
import config from '../config';

export const validateToken = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }

    try {
        const response = await axios.post(`${config.backendUrl}/validate-token`, { token });
        return response.data.valid;
    } catch (error) {
        console.error('Error validating token', error);
        return false;
    }
};
