import React from 'react';
import { Typography } from '@mui/material';

const Security = () => (
    <div>
        <Typography variant="h3">Security</Typography>
    </div>
);

export default Security;
