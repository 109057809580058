import React from 'react';
import { Typography } from '@mui/material';

const Payments = () => (
    <div>
        <Typography variant="h3">Payments</Typography>
    </div>
);

export default Payments;
