import React from 'react';
import { Typography } from '@mui/material';

const Surveys = () => (
    <div>
        <Typography variant="h3">Surveys</Typography>
    </div>
);

export default Surveys;
