import React from 'react';
import { Typography } from '@mui/material';

const Home = () => (
    <div>
        <Typography variant="h3">Home</Typography>
    </div>
);

export default Home;
