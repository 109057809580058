// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Sidebar, { drawerWidth } from './Sidebar';
import Header from './Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { connectSocket, subscribeToTopic, disconnectSocket } from '../services/socket';

const Dashboard = () => {
    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        disconnectSocket();
        navigate('/login');
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user) {
            connectSocket(() => {
                subscribeToTopic(`/topic/userDeactivated`, (message) => {
                    const deactivatedUserId = JSON.parse(message.body);
                    if (deactivatedUserId === user.id) {
                        alert('Su cuenta ha sido desactivada');
                        handleLogout();
                    }
                });
            });
        }

        return () => {
            disconnectSocket();
        };
    }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header handleDrawerToggle={handleDrawerToggle} onLogout={handleLogout} />
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    mt: { xs: '56px', sm: '64px' },
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
};

export default Dashboard;
