import axios from 'axios';
import config from '../config';
import { showSuccessToast, showErrorToast } from '../api/api-notifications';

const userService = {
    getAllUsers: async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.backendUrl}/api/users`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            //showSuccessToast('Usuarios cargados exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al cargar los usuarios';
            showErrorToast(message);
            throw error;
        }
    },

    getUserById: async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.backendUrl}/api/users/${id}`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Usuario cargado exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al cargar el usuario';
            showErrorToast(message);
            throw error;
        }
    },

    createUser: async (user) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${config.backendUrl}/api/users`, user, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Usuario creado exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al crear el usuario';
            showErrorToast(message);
            throw error;
        }
    },

    updateUser: async (id, user) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(`${config.backendUrl}/api/users/${id}`, user, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Usuario actualizado exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al actualizar el usuario';
            showErrorToast(message);
            throw error;
        }
    },

    deleteUser: async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`${config.backendUrl}/api/users/${id}`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Usuario eliminado exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al eliminar el usuario';
            showErrorToast(message);
            throw error;
        }
    },
};

export default userService;
