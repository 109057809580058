import React from 'react';
import { Typography } from '@mui/material';

const Notifications = () => (
    <div>
        <Typography variant="h3">Notifications</Typography>
    </div>
);

export default Notifications;
