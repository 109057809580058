import React, { useState, useEffect } from 'react';
import {
    Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, TablePagination, Typography
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Upload as UploadIcon, Add as AddIcon } from '@mui/icons-material';
import addressService from '../services/AddressService';
import * as XLSX from 'xlsx';

const AddressManagement = () => {
    const [addresses, setAddresses] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentAddress, setCurrentAddress] = useState({});
    const [file, setFile] = useState(null);
    const [streetNameFilter, setStreetNameFilter] = useState('');
    const [numberFilter, setNumberFilter] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Función para cargar las direcciones desde el backend al inicio
    const fetchAddresses = async () => {
        try {
            const addressesData = await addressService.getAllAddresses();
            setAddresses(addressesData); // Asigna la lista de direcciones al estado
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    useEffect(() => {
        fetchAddresses(); // Cargar direcciones al montar el componente
        if (file) {
            handleFileUpload();
        }
    }, [file]);

    const handleOpen = (address = {}) => {
        setIsEditing(!!address.id);
        setCurrentAddress(address);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentAddress({});
    };

    const handleChange = (e) => {
        setCurrentAddress({ ...currentAddress, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        try {
            if (isEditing) {
                await addressService.updateAddress(currentAddress.id, currentAddress);
            } else {
                await addressService.createAddress(currentAddress);
            }
            fetchAddresses(); // Actualizar la lista de direcciones después de guardar
            handleClose();
        } catch (error) {
            console.error('Error saving address:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await addressService.deleteAddress(id);
            fetchAddresses(); // Actualizar la lista de direcciones después de eliminar
        } catch (error) {
            console.error('Error deleting address:', error);
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!file) {
            alert('Por favor, selecciona un archivo primero.');
            return;
        }

        const reader = new FileReader();
        reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(sheet);

            const transformedData = data.map(row => ({
                streetName: row['Calle'],
                number: row['Numero']
            }));

            try {
                await addressService.bulkUploadAddresses(transformedData);
                fetchAddresses();
            } catch (error) {
                console.error('Error al cargar las direcciones:', error);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    // Filtrar direcciones basadas en los filtros de "Nombre de la Calle" y "Número"
    const filteredAddresses = addresses.filter(address =>
        address.streetName.toLowerCase().includes(streetNameFilter.toLowerCase()) &&
        address.number.toString().includes(numberFilter)
    );

    // Paginación
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                <Box display="flex">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpen({})}
                        sx={{ marginRight: 2 }}
                    >
                        <AddIcon /> Nueva Dirección
                    </Button>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    component="label"
                >
                    <UploadIcon /> Cargar desde excel
                    <input
                        type="file"
                        hidden
                        accept=".xlsx, .xls"
                        onChange={handleFileChange}
                    />
                </Button>
            </Box>

            {/* Filtros de búsqueda */}
            <Box display="flex" justifyContent="space-between" mt={2}>
                <TextField
                    label="Filtrar por Nombre de la Calle"
                    variant="outlined"
                    value={streetNameFilter}
                    onChange={(e) => setStreetNameFilter(e.target.value)}
                    sx={{ marginRight: 2, width: '48%' }}
                />
                <TextField
                    label="Filtrar por Número"
                    variant="outlined"
                    value={numberFilter}
                    onChange={(e) => setNumberFilter(e.target.value)}
                    sx={{ width: '48%' }}
                />
            </Box>

            <TableContainer component={Paper} sx={{ mt: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nombre de la Calle</TableCell>
                            <TableCell>Número</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAddresses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((address) => (
                            <TableRow key={address.id}>
                                <TableCell>{address.id}</TableCell>
                                <TableCell>{address.streetName}</TableCell>
                                <TableCell>{address.number}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleOpen(address)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDelete(address.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
            <TablePagination
                component="div"
                count={filteredAddresses.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
            />

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEditing ? 'Editar Dirección' : 'Añadir Dirección'}</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        name="streetName"
                        label="Nombre de la Calle"
                        type="text"
                        fullWidth
                        value={currentAddress.streetName || ''}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="number"
                        label="Número"
                        type="text"
                        fullWidth
                        value={currentAddress.number || ''}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        {isEditing ? 'Actualizar' : 'Aceptar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default AddressManagement;
