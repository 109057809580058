import axios from 'axios';
import config from '../config';
import { showSuccessToast, showErrorToast } from '../api/api-notifications';

const addressService = {
    getAllAddresses: async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.backendUrl}/api/addresses`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            //showSuccessToast('Direcciones cargadas exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al cargar las direcciones';
            showErrorToast(message);
            throw error;
        }
    },

    getAddressById: async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${config.backendUrl}/api/addresses/${id}`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Dirección cargada exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al cargar la dirección';
            showErrorToast(message);
            throw error;
        }
    },

    createAddress: async (address) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${config.backendUrl}/api/addresses`, address, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Dirección creada exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al crear la dirección';
            showErrorToast(message);
            throw error;
        }
    },

    updateAddress: async (id, address) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.put(`${config.backendUrl}/api/addresses/${id}`, address, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Dirección actualizada exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al actualizar la dirección';
            showErrorToast(message);
            throw error;
        }
    },

    deleteAddress: async (id) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`${config.backendUrl}/api/addresses/${id}`, {
                headers: {
                    Authorization: `${token}`,
                },
            });
            showSuccessToast('Dirección eliminada exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al eliminar la dirección';
            showErrorToast(message);
            throw error;
        }
    },

    bulkUploadAddresses: async (addresses) => {
        alert('aqui estoy')
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${config.backendUrl}/api/addresses/bulk`, addresses, {
                headers: {
                    Authorization: `${token}`,
                    'Content-Type': 'application/json',
                },
            });
            showSuccessToast('Direcciones cargadas masivamente exitosamente');
            return response.data;
        } catch (error) {
            const message = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : 'Error al cargar direcciones masivamente';
            showErrorToast(message);
            throw error;
        }
    }
};

export default addressService;
