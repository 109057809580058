import { Client } from '@stomp/stompjs';
import config from '../config';

let stompClient = null;
let subscriptions = {};

export const connectSocket = (onConnect) => {
    const socket = new WebSocket(config.socketUrl);

    stompClient = new Client({
        webSocketFactory: () => socket,
        debug: (str) => {
            console.log(str);
        },
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
    });

    stompClient.onConnect = () => {
        console.log('Conectado al WebSocket');
        if (onConnect) onConnect();
    };

    stompClient.activate();
};

export const subscribeToTopic = (topic, callback) => {
    if (stompClient && stompClient.connected) {
        const subscription = stompClient.subscribe(topic, callback);
        subscriptions[topic] = subscription;
    }
};

export const unsubscribeFromTopic = (topic) => {
    if (subscriptions[topic]) {
        subscriptions[topic].unsubscribe();
        delete subscriptions[topic];
    }
};

export const disconnectSocket = () => {
    if (stompClient !== null) {
        stompClient.deactivate();
        subscriptions = {};
    }
};
