import React from 'react';
import { Typography } from '@mui/material';

const Residents = () => (
    <div>
        <Typography variant="h3">Residents</Typography>
    </div>
);

export default Residents;
